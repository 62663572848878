<template>
	<i v-if="iconFileName.indexOf('el-icon-') === 0" :class="iconFileName" />
	<svg v-else class="svg-icon" aria-hidden="true" v-on="$listeners" :style="style">
		<use :xlink:href="`#icon-${iconFileName}`" />
	</svg>
</template>

<script>
	export default {
		name: 'SvgIcon',
		props: {
			iconFileName: {
				type: String,
				required: true
			},
			// 颜色值
			color: {
				type: String,
				default: "#999999"
			},
			// 颜色值
			height: {
				type: String,
				default: "1.5rem"
			},
			// 颜色值
			width: {
				type: String,
				default: "1.5rem"
			}
		},
		created() {
		},
		computed: {
			style() {
				return {
					"color": this.color,
					"height":this.height,
					"width":this.width
				};
			}
		}
	}
</script>

<style scoped>
	.svg-icon {
		/* width: 1.5em;
		height: 1.5em; */
		overflow: hidden;
		vertical-align: -0.15em;
		fill: currentColor;
		color: var(--color);
	}
</style>
