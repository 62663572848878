<!--
 * @Author        : Jarcem
 * @Date          : 2022-10-09 17:24:50
 * @LastEditTime  : 2022-10-24 00:40:22
 * @LastEditors   : Jarcem
 * @Description   : 
 * @FilePath      : \home-page-of-leo-software\src\App.vue
 * 
-->
<template>
	<div id="app">
		<router-view></router-view>
		<return-top></return-top>
	</div>
</template>

<script>
import returnTop from "./components/returnTop.vue"
export default {
	name: 'App',
	components: {
		returnTop
	},
	data() {
		return {
			screenWidth: document.body.clientWidth, // 屏幕宽度
			screenHeight: document.body.clientHeight, // 屏幕高度
		}
	},
	created() {
	
		// let zoom=(this.screenWidth/1493).toFixed(2)
		// if(this.screenWidth<480){
		// 	document.querySelector('body').setAttribute('style', 'zoom:'+zoom)
		// }

	},


}
</script>

<style scoped>
@import "@/assets/css/style.css";

#app {
	/* font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50; */
	/* margin-top: 60px; */


}

a {
	text-decoration: none
}

;

#app img {
	border-radius: 0.2rem;
}</style>
