/**
 * @Author        : Jarcem
 * @Date          : 2022-10-09 17:24:50
 * @LastEditTime  : 2022-11-08 12:58:24
 * @LastEditors   : Jarcem
 * @Description   : 
 * @FilePath      : /home-page-of-leo-software/src/router/index.js
 * @
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const Solution = () => import("../pages/solution.vue");
const Index = () => import("../pages/index.vue");
const anLi = () => import("../pages/case.vue");
const product = () => import("@/pages/product.vue");
const aboutUs = () => import("@/pages/aboutUs.vue");
const newsDetails= () => import("@/pages/news/details.vue");
const news= () => import("@/pages/news/news.vue");
const routes = [

	{
		path: "/",
		name: "index",
		component: Index,
		
	},
	{
		path: "/solution",
		name: "solution",
		component: Solution,
		
	},
	{
		path: "/case",
		name: "case",
		component: anLi,
		
	},
	{
		path: "/product",
		name: "product",
		component: product,
		
	},
	{
		path: "/aboutUs",
		name: "aboutUs",
		component: aboutUs,
		
	},
	{
		path: "/newsDetails",
		name: "newsDetails",
		component: newsDetails,
		
	},
	{
		path: "/news",
		name: "news",
		component: news,
		
	},
];

const router = new VueRouter({
	routes,
	mode: "hash",
	// base: '/dist'
});

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	window.scrollTo(0,0);
	next();
});
// 解决Loading chunk (\d)+ failed问题
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    if(isChunkLoadFailed){
    	// 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
        location.reload();
       
    }
    
  });

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

let originalPush = VueRouter.prototype.push; // 先保存一份 VueRouter.prototype.push方法
let originalRepace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
	// call(); 篡改上下文    catch(); 捕获异常 **** 
	return originalPush.call(this, location).catch(e => e);
}
VueRouter.prototype.replace = function replace(location) {
	return originalRepace.call(this, location).catch(e => e);
}

export default router;
